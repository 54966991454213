export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | SMS reminder</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong>SMS Reminder Service</strong>
              <h1>
                Never Miss <br />
                an Opportunity
              </h1>
              <p>
                Increase loyalty and enhance customer satisfaction with SMS
                reminders. Send event, call, attendance, and more reminders to
                never miss an appointment again.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta"
                  >Try for Free
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/sms-reminder-intro.png"
                alt="SMS reminder"
                width="472"
                height="463"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">67%</div>
              <p>of phone users prefer getting appointment reminders by text</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">62%</div>
              <p>
                of millennials prefer businesses to confirm appointments via
                text
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">7-9%</div>
              <p>of loan payments take place on-time thanks to SMS reminders</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="trending-use-case-reminder">
      <div class="container">
        <h3 class="text-center">Trending Use Cases of SMS Reminders</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Payment Reminders</h4>
              <div class="msg">
                Hi Patricia, this is a friendly reminder that your next payment
                is due on Friday. Send an email to Dealer & Dealt mentioning
                your check or submit it online: bit.ly/p1we1
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Appointment Confirmations</h4>
              <div class="msg">
                Hi Christopher! Your appointment with Dr. Brown is this Friday
                at 3 p.m. If you need to cancel or reschedule, do it here:
                bit.ly/p2owe2
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Meeting Reminders</h4>
              <div class="msg">
                Hi Patricia! It’s Flexi Global reminding you about your
                appointment this Friday at 3 p.m. Follow this link to cancel or
                reschedule: bit.ly/p3owe3
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Event Reminders</h4>
              <div class="msg">
                Dear parents, join us at the Annual Sports Festival tomorrow
                from 9 a.m.–1 p.m. Call the administration for further details:
                +1-202-555-0157.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Call Reminders</h4>
              <div class="msg">
                Hi Christopher! Redwood Realtors is reminding you about your
                upcoming call tomorrow at 3:00 p.m. Click here to cancel or
                reschedule: bit.ly/p4owe4
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Attendance Reminders</h4>
              <div class="msg">
                Hello Patricia, you have an Environmental Ethics and the Real
                World class to attend on 07.06.21 from 10 a.m.–2 p.m.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-reminder-use-case">
      <div class="sms-reminder-container">
        <h3>Choose Between 2 Ways of <span>Sending Message Reminders</span></h3>
        <div class="row">
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="inner">
              <h4>Platform</h4>
              <p>
                Manually schedule all text reminders on our platform. Manage
                your contacts and send time-sensitive reminders without coding
                if you are not using a CRM system for management.
              </p>
              <img
                class="d-lg-none d-md-none d-sm-block"
                src="imgs/sms-reminder-platform.png"
                alt="sms reminder platform"
              />
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="inner inner-right-end">
              <h4>API</h4>
              <p>
                Integrate our communication API directly to your CRM with a few
                lines of code and send automatically triggered SMS reminders to
                your customers.
              </p>
              <img
                class="d-lg-none d-md-none d-sm-block"
                src="imgs/sms-reminder-api.png"
                alt="sms reminder API"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container d-lg-block d-md-block d-none">
        <div class="row">
          <div class="col-6">
            <div class="img-wrapper">
              <img
                src="imgs/sms-reminder-platform.png"
                alt="sms reminder platform"
                height="389"
              />
            </div>
          </div>
          <div class="col-6 text-center">
            <div class="img-wrapper">
              <img
                src="imgs/sms-reminder-api.png"
                alt="sms reminder API"
                width="510"
                height="338"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="needs">
      <div class="container">
        <h3 class="text-center">Why You Need an SMS Reminder Service</h3>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <img src="imgs/finance-lg.svg" alt="less late payments" />
              <h5>Less late payments</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <img src="imgs/no-shows.svg" alt="Fewer no shows" />
              <h5>Fewer no shows</h5>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <img
                src="imgs/mobile-optimized.svg"
                alt="Less time spent making reminder calls"
              />
              <h5>Less time spent making reminder <br />calls</h5>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-reminder-industries">
      <h3 class="text-center">Industries That Use SMS Reminders the Most</h3>
      <div class="industries">
        <div class="industry">
          <img src="imgs/health.svg" alt="healtchare" />
          <span>Healthcare</span>
        </div>
        <div class="industry">
          <img src="imgs/finance.svg" alt="Finance" />
          <span>Financial Services</span>
        </div>
        <div class="industry">
          <img src="imgs/education.svg" alt="Education" />
          <span>Education</span>
        </div>
        <div class="industry">
          <img src="imgs/legal.svg" alt="Legal" />
          <span>Legal</span>
        </div>
        <div class="industry">
          <img src="imgs/wellness.svg" alt="wellness" />
          <span>Wellness</span>
        </div>
      </div>
      <div class="container">
        <h3 class="text-center">Why Dexatel?</h3>
        <div class="row statistics">
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="title">Global Reach</div>
              <p>
                Our 300 direct partners allow us to cover every single country.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="title">Transparent Pricing</div>
              <p>
                We offer pay-as-you-go pricing without any hidden or monthly
                fees.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="title">Dedicated Support</div>
              <p>
                Our team is available and reachable whenever you seek
                assistance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="registration-process">
      <div class="container">
        <div class="inner">
          <p>Contact our team or try our platform for free</p>
          <div class="cta cta-blue">
            <a href="https://dashboard.dexatel.com/sign-up"> Try for Free </a>
          </div>
          <div class="cta cta-dark">
            <a href="https://dexatel.com/contact-us/"> Contact Us </a>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`