export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | SMS Alert Service</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="../css/style.css" rel="stylesheet" type="text/css" />
     </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong> SMS Alert Service </strong>
              <h1>Notify at Once</h1>
              <p>
                From low balance notifications to tornado warnings, SMS alert is
                the best solution to deliver important and time-sensitive
                updates for any purpose.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta">Start for Free </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/sms-alert-intro.png"
                alt="SMS marketing"
                width="384"
                height="463"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <h2 class="text-center">SMS Alert Statistics to Consider</h2>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">90%</div>
              <p>of users check SMS promotional messages within 3 minutes</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">97%</div>
              <p>
                of Americans use their built-in text messaging apps at least
                once a day
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">7 billion</div>
              <p>people are expected to use mobile phones by 2024</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing">
      <div class="container sms-marketing-container">
        <div class="content">
          <h3>
            Connect with your<br />
            Community using SMS Alerts
          </h3>
          <p>
            Whether you’re a local bar or a restaurant, a gas station or a large corporation, getting important information out to the public
            as quickly as possible is essential. If your business has critical
            news to share, there is no better way than SMS alerts.
            <br />
            <br />
            With an open rate of 98% within three minutes of delivery, no other
            form of communication can guarantee such high visibility in such a
            short amount of time.
          </p>
        </div>
        <div class="img-wrapper">
          <img
            src="imgs/sms-alert-platform.png"
            alt="SMS Alert"
            width="503"
          />
        </div>
      </div>
    </section>
    <section id="trending-use-case" class="sms-alert-trending">
      <div class="container">
        <h2 class="text-center">Text Alerts for Businesses</h2>
        <p class="subtitle text-center">
          Start using SMS alerts to automate business communications and
          operational processes. But first, explore what kind of mobile text
          alerts your industry experts send. Not only does this ensure your
          current tactics are optimal, but it also helps implement more use
          cases to take full advantage of SMS alert services.
        </p>
        <div class="row trending-inner">
          <h3 class="text-center small-title">
            These are the top three industries that use SMS alert systems.
          </h3>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Financial Services</h4>
            <p>
                <ul>
                    <li>Low balance alerts</li>
                    <li>Transaction completed alerts</li>
                    <li>Fraud attempt alerts</li>
                    <li>Important email/call alerts</li>
                    <li>COVID-19 spread alerts</li>
                </ul>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Education</h4>
            <p>
                <ul>
                    <li>Emergency alerts</li>
                    <li>New meeting alerts</li>
                    <li>Working schedule alerts</li>
                    <li>Important email or call alerts</li>
                    <li>COVID-19 spread alerts</li>
                </ul>
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">eCommerce</h4>
            <p>
                <ul>
                    <li>Out-of-stock alerts</li>
                    <li>Back-in-stock alerts</li>
                    <li>ETA alerts</li>
                    <li>Pricing adjustment alerts</li>
                    <li>Fulfillment delay alerts</li>
                    <li>Lead alerts</li>
                </ul>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="choosing-sms-alert">
        <div class="container">
            <h3 class="text-center">
                Choosing the Best <span>SMS Alert Service</span>
            </h3>
            <div class="inner">
                <div class="item">
                    <img src="imgs/pricing.svg"/>
                    <h5>Affordable pricing</h5>
                </div>
                <div class="item">
                    <img src="imgs/delivery.svg"/>
                    <h5>99.98% deliverability</h5>
                </div>
                <div class="item">
                    <img src="imgs/uptime.svg"/>
                    <h5>100% uptime</h5>
                </div>
            </div>
            <p class="text-center">
                SMS alerts work through API integration since they are custom-triggered after an action or event. The most important thing to check before making a final decision is the flexibility of the SMS API. Review the <a href="#">API documentation</a> to understand what you can achieve with API integration.
            </p>
            <div class="text-center">
                <div class="cta cta-blue">
                    <a href="https://dashboard.dexatel.com/sign-up">Try Now</a>
                </div>
            </div>
        </div>
    </section>
    <section id="talk-to-expert">
        <div class="container form-container">
          <h3 class="text-center">Talk to an <span>expert</span></h3>
          <p class="subtitle text-center">
            Fill up the form and our experts will reach out to schedule a consultation session.
          </p>
          <div id="sf3z1fddd7649d16bcac4115789f849d416110167f8a808a53d4b397dec141ddc5fc" data-type="signupform">
            <input type="hidden" id="recapTheme" value="2" />
            <input type="hidden" id="recapMode" value="776121000013394057" />
            <input type="hidden" id="signupFormType" value="LargeForm_Vertical" />
            
            <form method="POST" id="zcampaignOptinForm" style="margin: 0px" action="https://xatl.maillist-manage.com/weboptin.zc">
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="FIRSTNAME"> First Name </label>
                  <input type="text" name="FIRSTNAME" placeholder="John" id="FIRSTNAME" />
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="LASTNAME"> Last Name </label>
                  <input type="text" name="LASTNAME" placeholder="Smith" id="LASTNAME" />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="CONTACT_EMAIL"> Last Name </label>
                  <input
                    type="email"
                    name="CONTACT_EMAIL"
                    placeholder="John@email.com"
                    id="CONTACT_EMAIL"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="PHONE"> Phone Number </label>
                  <input
                    type="tel"
                    name="PHONE"
                    placeholder="+123 456 678"
                    id="tel"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="COMPANYNAME">Company Name</label>
                  <input
                    type="text"
                    name="COMPANYNAME"
                    placeholder="Google"
                    id="COMPANYNAME"
                  />
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="CONTACT_CF5">Department </label>
                  <select id="interest" name="CONTACT_CF5" id="CONTACT_CF5">
                    <option value="Marketing">Marketing</option>
                    <option value="Development">Development</option>
                    <option value="IT & Operations">IT & Operations</option>
                    <option value="Customer Support">Customer Support</option>
                    <option value="Billing">Billing</option>
                    <option value="Human Resources">Human Resources</option>
                    <option value="Other">Other</option>
                  </select>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="country">Country</label>
                  <select id="country" name="CONTACT_CF1">
                    <option>select country</option>
                    <option value="AF">Afghanistan</option>
                    <option value="AX">Aland Islands</option>
                    <option value="AL">Albania</option>
                    <option value="DZ">Algeria</option>
                    <option value="AS">American Samoa</option>
                    <option value="AD">Andorra</option>
                    <option value="AO">Angola</option>
                    <option value="AI">Anguilla</option>
                    <option value="AQ">Antarctica</option>
                    <option value="AG">Antigua and Barbuda</option>
                    <option value="AR">Argentina</option>
                    <option value="AM">Armenia</option>
                    <option value="AW">Aruba</option>
                    <option value="AU">Australia</option>
                    <option value="AT">Austria</option>
                    <option value="AZ">Azerbaijan</option>
                    <option value="BS">Bahamas</option>
                    <option value="BH">Bahrain</option>
                    <option value="BD">Bangladesh</option>
                    <option value="BB">Barbados</option>
                    <option value="BY">Belarus</option>
                    <option value="BE">Belgium</option>
                    <option value="BZ">Belize</option>
                    <option value="BJ">Benin</option>
                    <option value="BM">Bermuda</option>
                    <option value="BT">Bhutan</option>
                    <option value="BO">Bolivia</option>
                    <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                    <option value="BA">Bosnia and Herzegovina</option>
                    <option value="BW">Botswana</option>
                    <option value="BV">Bouvet Island</option>
                    <option value="BR">Brazil</option>
                    <option value="IO">British Indian Ocean Territory</option>
                    <option value="BN">Brunei Darussalam</option>
                    <option value="BG">Bulgaria</option>
                    <option value="BF">Burkina Faso</option>
                    <option value="BI">Burundi</option>
                    <option value="KH">Cambodia</option>
                    <option value="CM">Cameroon</option>
                    <option value="CA">Canada</option>
                    <option value="CV">Cape Verde</option>
                    <option value="KY">Cayman Islands</option>
                    <option value="CF">Central African Republic</option>
                    <option value="TD">Chad</option>
                    <option value="CL">Chile</option>
                    <option value="CN">China</option>
                    <option value="CX">Christmas Island</option>
                    <option value="CC">Cocos (Keeling) Islands</option>
                    <option value="CO">Colombia</option>
                    <option value="KM">Comoros</option>
                    <option value="CG">Congo</option>
                    <option value="CD">
                      Congo, Democratic Republic of the Congo
                    </option>
                    <option value="CK">Cook Islands</option>
                    <option value="CR">Costa Rica</option>
                    <option value="CI">Cote D'Ivoire</option>
                    <option value="HR">Croatia</option>
                    <option value="CU">Cuba</option>
                    <option value="CW">Curacao</option>
                    <option value="CY">Cyprus</option>
                    <option value="CZ">Czech Republic</option>
                    <option value="DK">Denmark</option>
                    <option value="DJ">Djibouti</option>
                    <option value="DM">Dominica</option>
                    <option value="DO">Dominican Republic</option>
                    <option value="EC">Ecuador</option>
                    <option value="EG">Egypt</option>
                    <option value="SV">El Salvador</option>
                    <option value="GQ">Equatorial Guinea</option>
                    <option value="ER">Eritrea</option>
                    <option value="EE">Estonia</option>
                    <option value="ET">Ethiopia</option>
                    <option value="FK">Falkland Islands (Malvinas)</option>
                    <option value="FO">Faroe Islands</option>
                    <option value="FJ">Fiji</option>
                    <option value="FI">Finland</option>
                    <option value="FR">France</option>
                    <option value="GF">French Guiana</option>
                    <option value="PF">French Polynesia</option>
                    <option value="TF">French Southern Territories</option>
                    <option value="GA">Gabon</option>
                    <option value="GM">Gambia</option>
                    <option value="GE">Georgia</option>
                    <option value="DE">Germany</option>
                    <option value="GH">Ghana</option>
                    <option value="GI">Gibraltar</option>
                    <option value="GR">Greece</option>
                    <option value="GL">Greenland</option>
                    <option value="GD">Grenada</option>
                    <option value="GP">Guadeloupe</option>
                    <option value="GU">Guam</option>
                    <option value="GT">Guatemala</option>
                    <option value="GG">Guernsey</option>
                    <option value="GN">Guinea</option>
                    <option value="GW">Guinea-Bissau</option>
                    <option value="GY">Guyana</option>
                    <option value="HT">Haiti</option>
                    <option value="HM">Heard Island and Mcdonald Islands</option>
                    <option value="VA">Holy See (Vatican City State)</option>
                    <option value="HN">Honduras</option>
                    <option value="HK">Hong Kong</option>
                    <option value="HU">Hungary</option>
                    <option value="IS">Iceland</option>
                    <option value="IN">India</option>
                    <option value="ID">Indonesia</option>
                    <option value="IR">Iran, Islamic Republic of</option>
                    <option value="IQ">Iraq</option>
                    <option value="IE">Ireland</option>
                    <option value="IM">Isle of Man</option>
                    <option value="IL">Israel</option>
                    <option value="IT">Italy</option>
                    <option value="JM">Jamaica</option>
                    <option value="JP">Japan</option>
                    <option value="JE">Jersey</option>
                    <option value="JO">Jordan</option>
                    <option value="KZ">Kazakhstan</option>
                    <option value="KE">Kenya</option>
                    <option value="KI">Kiribati</option>
                    <option value="KP">
                      Korea, Democratic People's Republic of
                    </option>
                    <option value="KR">Korea, Republic of</option>
                    <option value="XK">Kosovo</option>
                    <option value="KW">Kuwait</option>
                    <option value="KG">Kyrgyzstan</option>
                    <option value="LA">Lao People's Democratic Republic</option>
                    <option value="LV">Latvia</option>
                    <option value="LB">Lebanon</option>
                    <option value="LS">Lesotho</option>
                    <option value="LR">Liberia</option>
                    <option value="LY">Libyan Arab Jamahiriya</option>
                    <option value="LI">Liechtenstein</option>
                    <option value="LT">Lithuania</option>
                    <option value="LU">Luxembourg</option>
                    <option value="MO">Macao</option>
                    <option value="MK">
                      Macedonia, the Former Yugoslav Republic of
                    </option>
                    <option value="MG">Madagascar</option>
                    <option value="MW">Malawi</option>
                    <option value="MY">Malaysia</option>
                    <option value="MV">Maldives</option>
                    <option value="ML">Mali</option>
                    <option value="MT">Malta</option>
                    <option value="MH">Marshall Islands</option>
                    <option value="MQ">Martinique</option>
                    <option value="MR">Mauritania</option>
                    <option value="MU">Mauritius</option>
                    <option value="YT">Mayotte</option>
                    <option value="MX">Mexico</option>
                    <option value="FM">Micronesia, Federated States of</option>
                    <option value="MD">Moldova, Republic of</option>
                    <option value="MC">Monaco</option>
                    <option value="MN">Mongolia</option>
                    <option value="ME">Montenegro</option>
                    <option value="MS">Montserrat</option>
                    <option value="MA">Morocco</option>
                    <option value="MZ">Mozambique</option>
                    <option value="MM">Myanmar</option>
                    <option value="NA">Namibia</option>
                    <option value="NR">Nauru</option>
                    <option value="NP">Nepal</option>
                    <option value="NL">Netherlands</option>
                    <option value="AN">Netherlands Antilles</option>
                    <option value="NC">New Caledonia</option>
                    <option value="NZ">New Zealand</option>
                    <option value="NI">Nicaragua</option>
                    <option value="NE">Niger</option>
                    <option value="NG">Nigeria</option>
                    <option value="NU">Niue</option>
                    <option value="NF">Norfolk Island</option>
                    <option value="MP">Northern Mariana Islands</option>
                    <option value="NO">Norway</option>
                    <option value="OM">Oman</option>
                    <option value="PK">Pakistan</option>
                    <option value="PW">Palau</option>
                    <option value="PS">Palestinian Territory, Occupied</option>
                    <option value="PA">Panama</option>
                    <option value="PG">Papua New Guinea</option>
                    <option value="PY">Paraguay</option>
                    <option value="PE">Peru</option>
                    <option value="PH">Philippines</option>
                    <option value="PN">Pitcairn</option>
                    <option value="PL">Poland</option>
                    <option value="PT">Portugal</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="QA">Qatar</option>
                    <option value="RE">Reunion</option>
                    <option value="RO">Romania</option>
                    <option value="RU">Russian Federation</option>
                    <option value="RW">Rwanda</option>
                    <option value="BL">Saint Barthelemy</option>
                    <option value="SH">Saint Helena</option>
                    <option value="KN">Saint Kitts and Nevis</option>
                    <option value="LC">Saint Lucia</option>
                    <option value="MF">Saint Martin</option>
                    <option value="PM">Saint Pierre and Miquelon</option>
                    <option value="VC">Saint Vincent and the Grenadines</option>
                    <option value="WS">Samoa</option>
                    <option value="SM">San Marino</option>
                    <option value="ST">Sao Tome and Principe</option>
                    <option value="SA">Saudi Arabia</option>
                    <option value="SN">Senegal</option>
                    <option value="RS">Serbia</option>
                    <option value="CS">Serbia and Montenegro</option>
                    <option value="SC">Seychelles</option>
                    <option value="SL">Sierra Leone</option>
                    <option value="SG">Singapore</option>
                    <option value="SX">Sint Maarten</option>
                    <option value="SK">Slovakia</option>
                    <option value="SI">Slovenia</option>
                    <option value="SB">Solomon Islands</option>
                    <option value="SO">Somalia</option>
                    <option value="ZA">South Africa</option>
                    <option value="GS">
                      South Georgia and the South Sandwich Islands
                    </option>
                    <option value="SS">South Sudan</option>
                    <option value="ES">Spain</option>
                    <option value="LK">Sri Lanka</option>
                    <option value="SD">Sudan</option>
                    <option value="SR">Suriname</option>
                    <option value="SJ">Svalbard and Jan Mayen</option>
                    <option value="SZ">Swaziland</option>
                    <option value="SE">Sweden</option>
                    <option value="CH">Switzerland</option>
                    <option value="SY">Syrian Arab Republic</option>
                    <option value="TW">Taiwan, Province of China</option>
                    <option value="TJ">Tajikistan</option>
                    <option value="TZ">Tanzania, United Republic of</option>
                    <option value="TH">Thailand</option>
                    <option value="TL">Timor-Leste</option>
                    <option value="TG">Togo</option>
                    <option value="TK">Tokelau</option>
                    <option value="TO">Tonga</option>
                    <option value="TT">Trinidad and Tobago</option>
                    <option value="TN">Tunisia</option>
                    <option value="TR">Turkey</option>
                    <option value="TM">Turkmenistan</option>
                    <option value="TC">Turks and Caicos Islands</option>
                    <option value="TV">Tuvalu</option>
                    <option value="UG">Uganda</option>
                    <option value="UA">Ukraine</option>
                    <option value="AE">United Arab Emirates</option>
                    <option value="GB">United Kingdom</option>
                    <option value="US">United States</option>
                    <option value="UM">United States Minor Outlying Islands</option>
                    <option value="UY">Uruguay</option>
                    <option value="UZ">Uzbekistan</option>
                    <option value="VU">Vanuatu</option>
                    <option value="VE">Venezuela</option>
                    <option value="VN">Viet Nam</option>
                    <option value="VG">Virgin Islands, British</option>
                    <option value="VI">Virgin Islands, U.s.</option>
                    <option value="WF">Wallis and Futuna</option>
                    <option value="EH">Western Sahara</option>
                    <option value="YE">Yemen</option>
                    <option value="ZM">Zambia</option>
                    <option value="ZW">Zimbabwe</option>
                  </select>
                </div>
                <div class="col-lg-6 col-sm-12 col-xs-12">
                  <label for="interest">Primary Product Interest </label>
                  <select id="interest" name="CONTACT_CF3">
                    <option value="Marketing">Marketing</option>
                    <option value="Authentication">Authentication</option>
                    <option value="Alerts">Alerts</option>
                    <option value="Reminders">Reminders</option>
                    <option value="Service & Support">Service & Support</option>
                  </select>
                </div>
              </div>
              <div class="recapDivlight recaptcha" id="recapDiv" style="width: 302px; margin: 0 auto;"></div>
              <input type="hidden" id="secretid" value="6LdNeDUUAAAAAG5l7cJfv1AA5OKLslkrOa_xXxLs"/>
              <input type="hidden" id="fieldBorder" value="rgb(222, 222, 222)" />
              <input type="hidden" name="zc_trackCode" id="zc_trackCode" value="ZCFORMVIEW" onload=""/>
              <input type="hidden" id="submitType" name="submitType" value="optinCustomView" />
              <input type="hidden" id="lD" name="lD" value="" />
              <input type="hidden" name="emailReportId" id="emailReportId" value="" />
              <input type="hidden" name="zx" id="cmpZuid" value="12912fa66" />
              <input type="hidden" name="zcvers" value="3.0" />
              <input type="hidden" name="oldListIds" id="allCheckedListIds" value="" />
              <input type="hidden" id="mode" name="mode" value="OptinCreateView" />
              <input type="hidden" id="zcld" name="zcld" value="" />
              <input type="hidden" id="zctd" name="zctd" value="" />
              <input type="hidden" id="document_domain" value="zoho.com" />
              <input type="hidden" id="zc_Url" value="xatl.maillist-manage.com" />
              <input type="hidden" id="new_optin_response_in" value="0" />
              <input type="hidden" id="duplicate_optin_response_in" value="0" />
              <input type="hidden" id="zc_formIx" name="zc_formIx" value="3z1fddd7649d16bcac4115789f849d416110167f8a808a53d4b397dec141ddc5fc" />
              <input type="hidden" id="isCaptchaNeeded" value="true" />
                <div class="text-center">
                  <input type="submit" name="SIGNUP_SUBMIT_BUTTON" value="Talk to an expert" />
                </div>
            </form>
          </div>
        </div>
      </script>
    </section>
  </body>
</html>
`