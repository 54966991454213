export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | SMS API Platform</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong> SMS API Platform </strong>
              <h1>
                Automate <br />Your Business<br />
                Communications
              </h1>
              <p>
                Enjoy unlimited text messaging with our SMS API. Integrate your
                platform with Dexatel and experience smooth automated messaging
                to satisfy your business needs.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta"
                  >Start for Free
                </a>
              </div>
              <div class="cta cta-white">
                <a href="https://dexatel.com/developers/">
                  See Documentation
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/sms-api-intro.png"
                alt="SMS API platform"
                width="384"
                height="463"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing-usage">
      <div class="container">
        <h3 class="text-center">Test Our SMS API for Free</h3>
        <p>
          Send single or bulk messages by requesting to our API. In the body of
          the request, type in the recipients’ phone numbers, text messages, and
          additional data; our system handles the rest from there.
        </p>
        <div class="text-center">
          <img src="imgs/api-doc-ui.png" alt="API doc" />
        </div>
      </div>
    </section>
    <section id="sms-marketing">
      <div class="container sms-marketing-container">
        <div class="content">
          <h3>What Is an SMS API?</h3>
          <p>
            <strong>Software Integration Interface (SMS API)</strong> is a
            software interface that makes it possible to send short messages via
            a cloud hosted platform. SMS API provides an interconnection between
            applications. SMS service APIs typically support HTTP.
          </p>
        </div>

        <div class="img-wrapper">
          <img
            src="imgs/sms-api-integration.svg"
            alt="SMS Alert"
            width="580"
          />
        </div>
      </div>
    </section>
    <section id="sms-marketing-pricing">
      <div class="container">
        <h3 class="text-center">What SMS Gateway API Does</h3>
        <p class="sms-api-platform-white">
          A texting API enables you to send text messages directly from your
          application. It only requires your developers to incorporate the
          gateway features into your own website, app, software, or CRM system.
        </p>
        <p class="sms-api-platform-white">
          Our SMS gateway API allows sending text messages across all
          technological networks and platforms that your business uses. In a
          nutshell, it merges different systems into one practical control
          center.
        </p>
      </div>
    </section>
    <section id="features">
      <div class="container">
        <h3 class="text-center">
          Simplify Complicated Tasks <br />
          With Dexatel’s Text Message API
        </h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>User Authentication</h4>
                <p>
                  SMS OTP API is the fastest, most secure, and affordable method
                  to verify or authenticate users. Our Verify API carries out
                  this function within minutes.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Wishlist Reminders</h4>
                <p>
                  Automatically send text message reminders to users who added
                  products to their wishlist and have not bought them yet.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Message Forwarding</h4>
                <p>
                  Forward important messages sent to a business number directly
                  to your phone.
                </p>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>SMS Auto-Response</h4>
                <p>
                  Take advantage of creating keywords and setting
                  auto-responses. This feature allows users to send specific
                  keywords to your business and get a response within seconds.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Appointment Reminders</h4>
                <p>
                  Send appointment reminders to users by typing in codes to
                  implement, for instance, in your CRM system. With each added
                  appointment, the system automatically sends a reminder.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Surveys</h4>
                <p>
                  Gain valuable feedback on your products and services from
                  subscribers with an SMS survey to better understand your
                  customers needs.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="registration-process" class="sms-api-platform-steps">
      <div class="container">
        <h4 class="text-center">
          How To Create an SMS API Connection With Dexatel
        </h4>
        <div class="row">
          <div class="col-lg-3 col-sm-12">
            <div class="number">1</div>
            <div class="title">
              Log into your account on the Dexatel platform
            </div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">2</div>
            <div class="title">Go to APIs and click on “Add new key”</div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">3</div>
            <div class="title">Enter a name for your connection</div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">4</div>
            <div class="title">Click on “Create”</div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">5</div>
            <div class="title">Go to “Documentation”</div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">6</div>
            <div class="title">
              Set up necessary configuration from your application
            </div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">7</div>
            <div class="title">Send test messages to make a trial run</div>
          </div>
          <div class="col-lg-3 col-sm-12">
            <div class="number">8</div>
            <div class="title">
              Send SMS automatically according to our API rules
            </div>
          </div>
        </div>
        <div class="text-center">
          <div class="cta cta-blue">
            <a href="https://help.dexatel.com/how-to-start-api-integration ">
              Learn More
            </a>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-api-platform-last">
      <div class="container">
        <div class="inner">
          <div>
            <h5>SMS API Pricing</h5>
            <p>
              Our pay-as-you-go approach charges you per sent message, which is
              the<br />
              most affordable SMS gateway pricing model. You will be free of
              setup,<br />
              monthly, or any other fees.
            </p>
          </div>
          <div>
            <h5>Sandbox for Free SMS API Test</h5>
            <p>Generate a token to test our APIs free of charge.</p>
          </div>
        </div>
      </div>
    </section>
    <section id="trending-use-case" class="sms-api-platform-apis">
      <div class="container">
        <h3 class="text-center">Our APIs</h3>
        <div class="row trending-inner">
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="line"></div>
            <h4 class="title">SMS API</h4>
            <p>
              Automate your business communications and operation processes.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Lookup API</h4>
            <p>
              Check phone number status before sending an SMS to cut unnecessary
              expenses and maintain an up-to-date customer list.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Pricing API</h4>
            <p>
              Verify SMS pricing in every country for all operators with one
              request.
            </p>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Balance API</h4>
            <p>
              Review your balance to make sure you do not run out of credits and
              that your SMS messages are sent after a triggered action.
            </p>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`