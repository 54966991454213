export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | Viber Business</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong>Viber Business</strong>
              <h1>
                Enhance Business<br />
                Communication for <br />Global Outreach
              </h1>
              <p>
                Engage and connect with your customers with Viber Business
                Messages. Dexatel helps elevate your business communication to a
                worldwide level.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta">
                  Apply Now
                </a>
              </div>
              <div class="cta cta-white">
                <a
                  href="https://www.forbusiness.viber.com/en/messaging-partners/"
                  id="intro-cta"
                >
                  Find Us on Viber’s Partner List
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/viber-intro.png"
                alt="Viber chat with location"
                width="420"
                height="367"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <h2 class="text-center">Viber Business Statistics to Consider</h2>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">+1 Billion</div>
              <p>Viber users worldwide</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">193</div>
              <p>Countries actively use Viber</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">25%</div>
              <p>of users tap the “Buy” button in Viber message campaigns</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="features">
      <div class="container">
        <h3 class="text-center">Advantages of Viber Business Messages</h3>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <p>
                  Send detailed messages of up to 1000 <br />
                  characters that include necessary <br />information of
                  marketing value.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <p>
                  Take your brand awareness and loyalty to <br />
                  the next level by adding your logo to the <br />
                  messages.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <p>
                  Increase conversion rates and guide <br />
                  customers by adding designed and<br />
                  functional CTA buttons.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="available-formats">
      <div class="container">
        <div class="inner viber">
          <div class="inner-content">
            <h3>
              Available Formats <br />
              of Viber Business Messaging
            </h3>
            <img
              class="d-lg-none"
              src="imgs/viber-formats.png"
              alt="Viber screen shot"
            />
            <h4>Looking to create an engaging experience? We got you.</h4>
            <p>
              Text - Image - Audio - File - Emoji - Link - Location - Extension
              for feedback: JavaScript, HTML & CSS
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="viber-experience" id="experience">
      <div class="container">
        <h3 class="text-center">Trending Use Cases via Viber Business</h3>
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Custom-Triggered Product Recommendations</h5>
              </div>
              <div class="msg">
                Hi Christopher, the new Nike t-shirt, code 7856 is the ideal
                gift for the holidays. Find the full product description here:
                bit.ly/p1owe1
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Wishlist Reminders</h5>
              </div>
              <div class="msg">
                Hi Patricia, thanks for adding the newest Nike t-shirt to your
                wishlist. The product is currently available. Order it right
                away here: bit.ly/p2owe2
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Shipment Notifications</h5>
              </div>
              <div class="msg">
                Hi Christopher, we see that the current status of your order is
                on hold. Sorry for the inconvenience. You’ll receive an update
                on the delivery status shortly.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>One-Time Passwords</h5>
              </div>
              <div class="msg">
                Hi Patricia, here is your one-time passcode: 6587. Valid for 10
                minutes.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Live One-on-One Conversations</h5>
              </div>
              <div class="msg-block">
                <div class="msg msg-white">
                  How do I update my credit information?
                </div>
                <div class="msg">
                  Hi Patricia, go to Account > Settings > Billing Information
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5 class="text-center">Custom-Made Surveys</h5>
              </div>
              <div class="msg">
                Hey Patricia, how would you rate our services from 1 (lowest) to
                10 (highest)?
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5 class="text-center">Suspicious Account Activity Alerts</h5>
              </div>
              <div class="msg">
                Chris, we’ve detected unusual activity in your account. If it
                was not initiated by you, please call +1-202-555-0157. Find out
                more at bit.ly/p1owe1
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5 class="text-center">Booking Confirmations</h5>
              </div>
              <div class="msg">
                Hi Patricia, thank you for choosing Best Nest Hotel. We’re
                looking forward to your stay on 07.06.21.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5 class="text-center">Discount and Coupon Offers</h5>
              </div>
              <div class="msg">
                Hey Christopher, a few tickets are still available for Friday’s
                game against the Lakers. We can set you up with a 15% discount
                if you purchase them within an hour. Are you interested?
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="most-used">
      <div class="container">
        <div class="most-used-inner">
          <h3 class="text-center">Countries With the Most Viber Users</h3>
          <div class="row">
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/greece.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Greece
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/ukraine.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Ukraine
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/russia.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Russia
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/belarus.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Belarus
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/bulgaria.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Bulgaria
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/hungary.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Hungray
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/lithuania.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Lithuania
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/croatia.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Croatia
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/slovakia.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Slovakia
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/cyprus.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Cyprus
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/albania.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Albania
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/philippines.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Philippines
              </div>
            </div>
            <div class="col-lg-2 col-md-3 col-sm-4 col-6">
              <div class="country">
                <img
                  src="imgs/myanmar.svg"
                  alt="Greece flag"
                  width="32"
                  height="32"
                />
                Myanmar
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="brands">
      <h3 class="text-center">Big Brands Using Viber</h3>
      <div class="container">
        <div class="logos">
          <div>
            <img src="imgs/barcelona.svg" alt="F.C barcelona" />
          </div>
          <div>
            <img src="imgs/raiffeisen-bank.svg" alt="logo for raiffeisen" />
          </div>
          <div>
            <img src="imgs/nba.svg" alt="NBA Logo" />
          </div>
          <div>
            <img src="imgs/coca-cola.svg" alt="coca cola" />
          </div>
          <div>
            <img src="imgs/loreal.svg" alt="L'oreal" />
          </div>
          <div>
            <img src="imgs/wallet-one.svg" alt="wallet-one" />
          </div>
        </div>
        <p class="text-center text-sm">
          Coca-Cola managed to drive more than 680,000 users with chatbot
          interactions on Viber,<br />
          29,000 “under-the-cap” code redemptions, and a 30% conversion rate.
        </p>
      </div>
    </section>
    <section id="registration-process">
      <div class="container">
        <h4 class="text-center">Registration process</h4>
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="number">1</div>
            <div class="title">Submit Application Form</div>
            <div class="text">Simply submit your company information.</div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="number">2</div>
            <div class="title">Sign Warranties Letter</div>
            <div class="text">
              Sign a warranty ensuring that you won’t use Viber Business for
              spamming.
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="number">3</div>
            <div class="title">Sign Business Agreement</div>
            <div class="text">
              Sign an agreement with Dexatel to cover all financial aspects.
            </div>
          </div>
        </div>
        <div class="text-center">
          <p class="final-text">
            Congratulations, you are all set to send and serve!
          </p>
          <div class="cta cta-blue">
            <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta">
              Start Now</a
            >
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`