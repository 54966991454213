export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | WhatsApp Business API</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong>WhatsApp Business API</strong>
              <h1>
                Marketing <br />
                Without Marketing
              </h1>
              <p>
                Enhance your customer experience with our easy-to-use WhatsApp
                Business API. Automate communication with your customers through
                their favorite business messaging app.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta">
                  Start For Free
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/whatsapp-intro.png"
                alt="WhatsApp chat with location"
                width="420"
                height="367"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <h2 class="text-center">
            WhatsApp Business Statistics to Keep In Mind
          </h2>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">1.6 Billion</div>
              <p>people use WhatsApp every month</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">5 Million</div>
              <p>companies use WhatsApp Business</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">180</div>
              <p>countries list WhatsApp as a super popular app</p>
            </div>
          </div>
          <div class="col-lg-3 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">68%</div>
              <p>
                of users consider WhatsApp a hassle-free means of connecting to
                businesses
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="features">
      <div class="container">
        <h3 class="text-center">
          Why You Should Start Using<br />
          WhatsApp Business?
        </h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Your Customers’ Favorite App</h4>
                <p>
                  When it comes to the best communication channel, your
                  customers’ needs come first. WhatsApp is now the number one
                  business messaging platform preferred by many customers.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>Useful Fallback to SMS</h4>
                <p>
                  We ensure that your messages are delivered even if your
                  customer does not have WhatsApp. In these cases, your message
                  is automatically converted to SMS.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>End-To-End Encryption</h4>
                <p>
                  WhatsApp is the most secure OTT application right now,
                  cementing its place as the top business messaging app on the
                  market
                </p>
              </div>
            </div>
          </div>
          <div class="separator"></div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>
                  “Delivered” and <br />
                  “Read” Message Receipts
                </h4>
                <p>
                  These two statuses—“delivered” and “read”—allow us to optimize
                  text messages for the highest conversion and filtrate
                  unreachable numbers.
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>
                  Seamless <br />
                  Customer Service Experience
                </h4>
                <p>
                  Connecting with your customers directly to answer their
                  questions and resolve issues creates a great customer
                  experience and strengthens their loyalty to your business."
                </p>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div>
                <img
                  src="imgs/check.svg"
                  alt="icon for features"
                  width="32"
                  height="32"
                />
              </div>
              <div>
                <h4>AI Chatbots and Automations</h4>
                <p>
                  WhatsApp Bot API saves your customer support specialists’ time
                  by filtering untargeted or spam conversations.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="available-formats">
      <div class="container">
        <div class="inner">
          <div class="inner-content">
            <h3>Available Formats of WhatsApp API</h3>
            <img
              class="d-lg-none"
              src="imgs/whatsapp-formats.png"
              alt="whatsApp screen shot"
            />
            <h4>Looking to create an engaging experience? We got you.</h4>
            <p>
              Text - Image - Audio - File - Emoji - Link - Location - Extension
              for feedback: JavaScript, HTML & CSS
            </p>
          </div>
        </div>
      </div>
    </section>
    <section id="experience">
      <div class="container">
        <h3 class="text-center">
          Upgrade your Customers' Experience by using<br />
          Whatsapp Messages API to
        </h3>
        <div class="row">
          <p class="title-sm text-center">Send Notifications and Alerts</p>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Personalized Suggestions</h5>
                <p>
                  Thanks to the Whatsapp API integration, we can send
                  custom-triggered recommendations.
                </p>
              </div>
              <div class="msg">
                Hi Patricia, the new Nike T-shirt, code 7856 is an awesome gift
                idea for the holidays. Find the full product description here:
                bit.ly/p3owe3.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Shipment Notifications</h5>
                <p>
                  The location-sharing feature allows us to automatically send
                  product shipping statuses.
                </p>
              </div>
              <div class="msg">
                Hi Christopher, your order has been successfully shipped. Click
                on the link to track your shipment: bit.ly/p2owe2
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Abandoned Cart Reminders</h5>
                <p>
                  Sending an abandoned cart recovery email can increase the
                  conversion rate by 10%.
                </p>
              </div>
              <div class="msg">
                Hi Patricia, we noticed you didn’t complete your order on
                Shirtera. Reply with HELP if you need our assistance or complete
                your order at bit.ly/p4owe4.
              </div>
            </div>
          </div>
        </div>
        <div class="separator"></div>
        <div class="row">
          <p class="title-sm text-center">
            Provide trustworthy and convenient live customer support
          </p>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Two-Way Conversations</h5>
                <p>
                  WhatsApp Business makes it easy to provide real-time customer
                  support through one-on-one communication.
                </p>
              </div>
              <div class="msg-block">
                <div class="msg msg-white">
                  How do I update my credit information?
                </div>
                <div class="msg">
                  Hi Patricia, go to Account > Settings > Billing Information
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5>Gather Customer Feedback</h5>
                <p>
                  Auto-triggered templates are a simple way to check the quality
                  of your service.
                </p>
              </div>
              <div class="msg">
                Hello Patricia! We hope you enjoyed your time at Flavoroso. How
                would you rate our services from 1 (very bad) to 10 (excellent)?
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="col-inner">
              <div>
                <h5 class="text-center">Secure Payments</h5>
                <img
                  src="imgs/img-tshirt.png"
                  width="282"
                  height="151"
                  alt="pattern shirt"
                />
              </div>
              <div class="msg msg-small mx-auto">
                Pattern Shirt - Buy now for $20 - More info: bit.ly/p3owe3
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="pricing">
      <div class="container">
        <h3 class="text-center">WhatsApp API Pricing</h3>
        <p class="text-center text-sm">
          There are different fees for a Whatsapp Business solution:
        </p>
      </div>
      <div class="inner-container mx-auto">
        <div class="row">
          <div class="col-lg-4 col-sm-12 text-center">
            <img
              src="imgs/icon-wallet.svg"
              width="89"
              height="89"
              alt="fixed fee"
            />
            <h6>Fixed monthly fees</h6>
          </div>
          <div class="col-lg-4 col-sm-12 text-center">
            <img
              src="imgs/icon-user.svg"
              width="89"
              height="89"
              alt="Fess per users"
            />
            <h6>
              Fees per monthly <br />
              active users
            </h6>
          </div>
          <div class="col-lg-4 col-sm-12 text-center">
            <img
              src="imgs/icon-notification.svg"
              width="89"
              height="89"
              alt="Fess per users"
            />
            <h6>Fees per notification</h6>
          </div>
        </div>
      </div>
    </section>
    <section id="registration-process">
      <div class="container">
        <h4 class="text-center">Registration process</h4>
        <div class="row">
          <div class="col-lg-4 col-sm-12">
            <div class="number">1</div>
            <div class="title">Account Registration</div>
            <div class="text">
              Mention your company name, logo, Facebook Business Manager ID,
              website, description, email address, phone number, and more
              details.
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="number">2</div>
            <div class="title">Account Validation</div>
            <div class="text">
              WhatsApp validates your company industry vertical with commerce
              policy. Phone numbers should also be validated through two-factor
              authentication.
            </div>
          </div>
          <div class="col-lg-4 col-sm-12">
            <div class="number">3</div>
            <div class="title">Business Manager ID Approval</div>
            <div class="text">
              Once you approve “Message On Behalf of Authority” sent by Dexatel,
              you will receive a notification in your Facebook Business Manager
              console.
            </div>
          </div>
        </div>
        <div class="text-center">
          <p class="final-text">
            Congratulations, you are all set to send and serve!
          </p>
          <div class="cta cta-blue">
            <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta">
              Start Now</a
            >
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`