export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | Personalized sms platform</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
   
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong>SMS Authentication Service</strong>
              <h1>
                Conduct Direct<br />
                Business Operations
              </h1>
              <p>
                Nothing is as important as a user's security. Our Verify API
                turns this into an effortless task that our technical team
                completes in just one day.
              </p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta"
                  >Start for Free
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/sms-intro.png"
                alt="Viber chat with location"
                width="384"
                height="463"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">85%</div>
              <p>conversion rate</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">>250%</div>
              <p>higher conversion compared to those completed via email</p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">99%</div>
              <p>of account thefts are eliminated according to Microsoft</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-auth">
      <div class="container">
        <div class="row">
          <div class="col-lg-7 col-md-6 col-sm-12">
            <div class="inner">
              <h3>What is SMS Authentication?</h3>
              <p>
                Phone authentication service helps verify a user's identity by
                sending a text message to their mobile devices. The message
                typically contains a 4-digit code, which users need to enter
                into the system to verify their identity. Text authentication
                done through OTP or TOTP adds an extra verification factor (2FA)
                to sign in with a password.
              </p>
            </div>
          </div>
          <div class="col-lg-5 col-md-6 col-sm-12">
            <div class="img-wrapper"><img src="imgs/sms-auth.svg" /></div>
          </div>
        </div>
      </div>
    </section>
    <section id="industries">
      <div class="container">
        <h3><span>Essential</span> for Several Industries</h3>
        <p class="subtitle">
          Many industries require SMS authentication on a daily basis. Websites
          that need to verify their users the most deal with online
          registrations, financial transactions, or sensitive private
          information. SMS authentication is also necessary to verify a user’s
          identity on social networks.
        </p>
        <div class="row">
          <div class="col-lg-6 col-sm-12">
            <div class="industries-inner">
              <h4>Top Industries That Implement SMS Authentication</h4>
              <p>
                Authentication services prevent fake and fraudulent accounts
                from registering. Similarly, retail stores also use SMS
                authentications to provide users access to a loyalty program or
                confirm a coupon or discount.
              </p>
            </div>
          </div>
          <div class="col-lg-6 col-sm-12">
            <div class="industries">
              <div class="industry">
                <img src="imgs/health.svg" alt="healtchare" />
                <span>Healthcare</span>
              </div>
              <div class="industry">
                <img src="imgs/entertainemnt.svg" alt="Entertainment" />
                <span>Entertainment</span>
              </div>
              <div class="industry">
                <img src="imgs/social.svg" alt="social" />
                <span>Social</span>
              </div>
              <div class="industry">
                <img src="imgs/ecommerce.svg" alt="ecommerce" />
                <span>eCommerce</span>
              </div>
              <div class="industry">
                <img src="imgs/gaming.svg" alt="Gaming Apps" />
                <span>Gaming Apps</span>
              </div>
              <div class="industry">
                <img src="imgs/saas.svg" alt="SaaS" />
                <span>B2B SaaS</span>
              </div>
              <div class="industry">
                <img src="imgs/finance.svg" alt="Financial" />
                <span>Financial Services</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="message-use-case">
      <div class="container">
        <h3 class="text-center">
          Trending Use Cases of <span>SMS Authentication</span>
        </h3>
        <div class="row justify-content-center">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div class="msg-wrapper">
                <div class="msg">
                  Hello Christopher, your one-time authentication code is 6587.
                  Use this to complete the registration procedure.
                </div>
              </div>
              <h5>New Registrations</h5>
              <p>
                Send new customers a one-time SMS passcode to make sure they’re
                real people.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div class="msg-wrapper">
                <div class="msg">
                  Hi Patricia, your one-time authentication code is 6587. Valid
                  for 10 minutes.
                </div>
              </div>

              <h5>Passwordless Authentications</h5>
              <p>
                Provide your users with the option of logging into their
                accounts without a password.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div class="msg-wrapper">
                <div class="msg">
                  Hi Christopher, your one-time authentication code is 6587 to
                  reset your password.
                </div>
              </div>
              <h5>Password Resets</h5>
              <p>
                Send SMS messages for password reset OTPs instead of emails.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div class="msg-wrapper">
                <div class="msg">
                  Hi Patricia, your transaction verification code is 7856. Valid
                  for 10 minutes.
                </div>
              </div>
              <h5>Transaction Authentication</h5>
              <p>
                Verify your users’ identities when changing their account
                information, accessing credit card details, transferring funds,
                or making payments.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <div class="msg-wrapper">
                <div class="msg">
                  Hi Christopher, your one-time authentication code is 6587 to
                  confirm your purchase.
                </div>
              </div>
              <h5>Purchase Confirmations</h5>
              <p>
                Secure your customers’ credit card and account balance by
                verifying every purchase made through their accounts.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-main-features">
      <div class="container">
        <h3>Advantages of Dexatel’s <span>Verify API</span></h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>Analyzing Performance</h5>
              <p>
                Track the passcode delivery rate to ensure you are using your
                customers’ preferred channel—email, SMS, voice, and so on.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>Ready-to-Go API</h5>
              <p>
                Our Verify API securely delivers OTP and TOTP messages. Not only
                does our Verify API ease your job, but it also saves you time.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>Personalized Channels</h5>
              <p>
                Let customers choose their preferred authentication channel—SMS,
                WhatsApp, Viber, RCS, Apple Business Chat, voice, or email.
                Implement fallback to make sure your messages are delivered in
                any given scenario.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-api-last">
      <div class="container">
        <div class="content">
          <h4>Accomplish This With Our <span>Verify API</span></h4>
          <p>
            Try out Dexatel’s Verify API free of charge to have a general idea
            of what it looks like and how it operates. Testing text
            authentication service for free is an asset we provide to every
            company.
          </p>
          <div class="cta cta-blue">
            <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta"
              >Try For Free
            </a>
          </div>
          <div class="cta cta-white">
            <a href="https://dexatel.com/contact-us/" id="intro-cta">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`