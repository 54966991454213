import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SmsAlert from "./pages/SmsAlert";
import SmsApi from "./pages/SmsApi";
import SmsAuthentication from "./pages/SmsAuthentication";
import SmsMarketing from "./pages/SmsMarketing";
import SmsPlatform from "./pages/SmsPlatform";
import SmsReminder from "./pages/SmsReminder";
import ViberBusiness from "./pages/ViberBusiness";
import WhatsappBusiness from "./pages/WhatsappBusiness";
import Renderer from "./Renderer";
import './css/style.css';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-000000-01');
ReactGA.pageview(window.location.pathname + window.location.search);

const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/sms-alert" element={<Renderer content={SmsAlert}/>}/>
                <Route exact path="/sms-api" element={<Renderer content={SmsApi}/>}/>
                <Route exact path="/sms-marketing" element={<Renderer content={SmsMarketing}/>}/>
                <Route exact path="/sms-platform" element={<Renderer content={SmsPlatform}/>}/>
                <Route exact path="/sms-reminder" element={<Renderer content={SmsReminder}/>}/>
                <Route exact path="/sms-authentication" element={<Renderer content={SmsAuthentication}/>}/>
                <Route exact path="/viber-business" element={<Renderer content={ViberBusiness}/>}/>
                <Route exact path="/whatsapp-business" element={<Renderer content={WhatsappBusiness}/>}/>
            </Routes>
        </Router>
    );
}

export default App;