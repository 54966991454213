export default `<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Dexatel | SMS Marketing Service</title>
    <meta charset="UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link
      href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css"
      rel="stylesheet"
      integrity="sha384-EVSTQN3/azprG1Anm3QDgpJLIm9Nao0Yz1ztcQTwFspd3yD65VohhpuuCOmLASjC"
      crossorigin="anonymous"
    />
    <link href="src/css/style.css" rel="stylesheet" type="text/css" />
  </head>
  <body>
    <section id="intro">
      <div class="container no-gutter">
        <div id="logo">
          <img src="imgs/logo.svg" height="50" />
        </div>
        <div class="row d-flex align-items-center" id="intro-row">
          <div class="col-lg-6 col-sm-12">
            <div class="title">
              <strong> SMS Marketing Service </strong>
              <h1>
                It's simple,<br />
                but it's powerful.
              </h1>
              <p>Are you taking advantage of the power of SMS marketing?</p>
              <div class="cta cta-blue">
                <a href="https://dashboard.dexatel.com/sign-up" id="intro-cta"
                  >Start for Free
                </a>
              </div>
            </div>
          </div>
          <div class="col text-end">
            <div class="img-wrapper">
              <img
                src="imgs/sms-marketing-intro.png"
                alt="SMS marketing"
                width="472"
                height="460"
                class="sms-marketing-img"
              />
            </div>
          </div>
        </div>
        <div class="row statistics" id="statistics">
          <h2 class="text-center">SMS Marketing Statistics to Know</h2>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">99%</div>
              <p>
                of all text messages are read within 5 minutes of being received
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">29%</div>
              <p>
                of text marketing recipients click on links they receive in SMS
                messages
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-sm-6 col-xs-12">
            <div
              class="col-inner text-center d-flex flex-column justify-content-center"
            >
              <div class="percentage">70%</div>
              <p>of phone users claim that SMS is an attention grabber</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing">
      <div class="container sms-marketing-container">
        <div class="content">
          <h3>What is SMS marketing?</h3>
          <p>
            One of the many mobile marketing solutions, SMS marketing (Short
            Message Service Marketing) is the process of sending promotional
            text messages to a target audience.
          </p>
        </div>
        <div class="img-wrapper">
          <img
            src="imgs/sms-marketing-platform.png"
            alt="SMS marketing"
            width="486"
            height="344"
          />
        </div>
      </div>
    </section>
    <section id="trending-use-case" class="sms-marketing-trending">
      <div class="container">
        <h3 class="text-center">
          Why You Should Start <br />
          Using a Text Marketing Service
        </h3>
        <div class="row trending-inner">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">It is Affordable</h4>
            <p>Pay only $5 per 1000 sent messages</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Faster Implementation</h4>
            <p>
              It only takes five minutes to launch your first SMS marketing
              campaign
            </p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Timely, Relevant Messages</h4>
            <p>Your customers check their mobile phones five times per hour</p>
          </div>
        </div>
      </div>
    </section>
    <section
      id="trending-use-case-reminder"
      class="sms-marketing-trending-use-case"
    >
      <div class="container">
        <h3 class="text-center">Trending Use Cases of SMS Marketing</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Time-Sensitive Offers</h4>
              <div class="msg">
                Hi Christopher, it’s getting hot out there! We’re giving an
                extra 20% off all summer items. Check out the hottest deals
                in-store and on our website at ​​bit.ly/p1we1
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Back-in-Stock Notifications</h4>
              <div class="msg">
                Hello Patricia, Nike T-shirt, code 7856 has been sold out for
                months, but they’re back now. Grab it before they sell out
                again! bit.ly/p1owe1
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>New Product Launch Notifications</h4>
              <div class="msg">
                Hi Christopher, good news. The brand new Dodge Challenger is
                here! Reply to this message and we’ll have you come for a quick
                test drive.
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Collect Customer Reviews</h4>
              <div class="msg">
                Hello Patricia! We hope you enjoyed your stay with us. How would
                you rate our services on a scale from 1 (very bad) to 10
                (excellent)?
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Abandoned Cart Reminders</h4>
              <div class="msg">
                Hi Christopher, we noticed you didn’t complete your order on
                Shirtera. Reply with HELP if you need our assistance or complete
                your order here: bit.ly/p4owe4
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="col-inner">
              <h4>Content Sharing</h4>
              <div class="msg">
                Hi Patricia, we posted a new blog about 2022 SMS marketing
                trends. Read on to keep up with the trends: bit.ly/p3owe3.
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="platform-features">
      <div class="container">
        <h3 class="text-center">Our SMS Marketing Platform is:</h3>
        <div class="row trending-inner">
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Compliant:</h4>
            <p>We are TCPA, HIPAA, PSD2, GDPR, PDPA compliant</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Personalized:</h4>
            <p>We provide custom SMS marketing strategies recommendations</p>
          </div>
          <div class="col-lg-4 col-md-4 col-sm-12">
            <div class="line"></div>
            <h4 class="title">Spam-free:</h4>
            <p>Customers have the option for an easy opt-out</p>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing-pricing">
      <div class="container">
        <h3 class="text-center">SMS Marketing Pricing</h3>
        <p>
          We use a pay-as-you-go pricing model, meaning we charge per sent
          message. SMS marketing costs vary depending on your destination.
        </p>
        <div class="text-center">
          <div class="cta cta-light">
            <a href="https://dexatel.com/pricing/ "> See Pricing </a>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing-steps">
      <div class="container">
        <h3 class="text-center">
          Take These Steps to Start <br />
          Using Text Message Marketing Services
        </h3>
        <div class="steps-inner">
          <div class="step">
            <img src="imgs/sign-up.svg" alt="Sign up" />
            <h5>Sign up</h5>
          </div>
          <div class="step">
            <img src="imgs/verify.svg" alt="Verify" />
            <h5>Get verified</h5>
          </div>
          <div class="step">
            <img src="imgs/test.svg" alt="Test" />
            <h5>Test how it works</h5>
          </div>
          <div class="step">
            <img src="imgs/email.svg" alt="Request" />
            <h5>Request or buy sender</h5>
          </div>
          <div class="step">
            <img src="imgs/rocket.svg" alt="campaign" />
            <h5>Start building your campaign</h5>
          </div>
        </div>
      </div>
    </section>
    <section id="sms-marketing-usage">
      <div class="container">
        <h3 class="text-center">Automate SMS Marketing for Optimal Results</h3>
        <p>
          Implement our flexible API to take advantage of automated SMS
          marketing
        </p>
        <div class="text-center">
          <div class="cta cta-blue">
            <a href="https://dexatel.com/developers/">
              Explore documentation
            </a>
          </div>
        </div>
        <div class="text-center">
          <img src="imgs/api-doc-ui.png" alt="API doc" />
        </div>
      </div>
    </section>
    <section id="sms-main-features">
      <div class="container">
        <h3>Why Dexatel?</h3>
        <div class="row">
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>Benefit from successful text message marketing services</h5>
              <p>
                Our platform automatically suggests eBooks, guides, and other
                helpful information regarding your location, industry, and
                target market.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>
                Take advantage of having an omnichannel communication platform
              </h5>
              <p>
                Our omnichannel solution covers all your SMS marketing needs to
                help you reach out to your customers through their preferred
                channels.
              </p>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-sm-12">
            <div class="inner">
              <h5>Choose the right channel with advanced analytics</h5>
              <p>
                We implement advanced analytics that allow you to track the
                highest-performing channels, templates, hours, and more within
                one interface.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </body>
</html>
`